<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				count_event:0,
            }
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				if(this.$route.query.search) query.search = this.$route.query.search
				return query
			}
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.$set(this.$root, 'page', this);
            this.getApi()
		},
        methods: {
        	getApi() {
				Gen.apirest("/list-event",this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data
					this.count_event = resp.count_event
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
        },
        watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row justify-content-around dark">
						<div class="col-md-12">
							<div class="heading_section">
								<h2>{{web.mn_event}}</h2> 
							</div>
						</div>
						<h3 v-if="count_event<1 || data.data.length==0"  style="color:#fff" class="text-center">{{ $route.query.search ? web.no_search_result+$route.query.search : web.lbl_no_data }}</h3>
						<div v-else v-for="(v,k) in data.data" :key="k" class="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
							<div class="row">
								<div class="col-md-6">
									<div class="flip-card">
										<div class="front" :style="'background-image: url('+uploader(v.image)">
											<div class="inner">
												<div class="feature-box media-box">
													<div class="date_wrapper">
														<h4>{{ v.d_publish }}</h4>
														<h3>{{ v.m_publish }}</h3>
														<p>{{ v.y_publish }}</p>
													</div>
												</div>
											</div>
										</div>
										<div class="back dark no-after" :style="'background-image: url('+uploader(v.image)">
											<div class="inner mt-3"></div>
										</div>
									</div>
								</div>
								<div class="col-md-6 pl-md-0" >
									<div class="wrap_event_p">
										<div class="event_wrap">
											<router-link :to="{name:'DetailEvent', params:{slug:v.slug}}">
												<h3>{{ v.title }}</h3>
											</router-link>
											<p class="mb-3" v-html="v.excerpt"></p>
											<router-link :to="{name:'DetailEvent', params:{slug:v.slug}}" class="btn_act">{{web.btn_view}}</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pagination class="justify-content-center mt-5" :data="data" :limit="8" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>